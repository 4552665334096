import React from "react"
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const LoginSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-3 pb-5"
      style={{
        background: "linear-gradient(#E5E8e9 30%, #EFF2F4 70%)",
        color: "#365c70",
      }}
    >

      <div className="container pl-5 pr-5 text-center" style={{width:"500px"}}>
        <div
          className="hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0 mt-5"
          style={{ fontSize: "24px", color: "#ff0000" }}
        >
        <h2>
          Sorry, Your Login Failed
        </h2>

        </div>



      </div>
    </section>
  )
}
export default LoginSection